<template>
  <div>
      <transition appear @before-enter="beforEnter" @enter="enter" :css="false">
        <div class="box"></div>
      </transition>
  </div>
</template>

<script>
import  gsap from "gsap";
export default {
  mounted() {
    console.log(gsap)
  },
  methods:{
    beforEnter(el){
      el.style.opacity = 0
      el.style.transform = 'scale(0,0)'
    },
    enter(el,done){
      gsap.to(el,1,{
        opacity : 1,
        scale:1,
        width:'200px',
        height:"200px",
        onComplete:done,

      })
    }
  }
}
</script>

<style scoped>
.box{
  background: red;
  width: 100px;
  height: 100px;
  margin: auto;
  transition: ease-in-out;
}

</style>