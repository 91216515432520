<template>
  <div>
      <v-card elevation="0">
        <v-card-title>
          todo List
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field label="todos" v-model="temp"/>
            </v-col>
            <v-col>
              <v-btn color="primary" @click="addItem">
                Add
              </v-btn>
              <v-btn color="blue" @click="Sort">
                Sort
              </v-btn>
            </v-col>
          </v-row>
          <transition name="alert">
            <v-alert type="warning" v-if="alert">
              <p>This field is required</p>
            </v-alert>
          </transition>
<!--          <transition-group tag="ul" name="list" appear>-->
<!--            <li v-for="(item , i) in items" :key="i">-->
<!--               {{item}}-->
<!--            </li>-->
<!--          </transition-group>-->
          <transition-group class="d-flex flex-column" name="card">
            <div
                style="flex: 1 0 0"
                @click="items.splice(index,1)" v-for="      (item,index) in items" :key="index">
              <div>
                {{item}}
              </div>
            </div>
          </transition-group>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items:[
          'Buy book',
          'doing Home work',
          'testing app'
      ],
      list: ['a','c','f','q'],
      temp:"",
      alert:false,
    }
  },
  methods:{
    addItem(){
      this.alert = false
      if (this.temp == ""){
        this.alert = true
            return;
      }
      this.items.unshift(this.temp)
      this.temp = ""
    },
    Sort(){
      this.list.sort()
    },
  }
}
</script>

<style scoped>

/*.list-enter{*/
/*  opacity: 0;*/
/*}*/
/*.list-enter-active{*/
/*  transition: opacity .3s ease;*/
/*}*/
/*.lis*/


.card-leave-active {
  position: absolute;
}

.card-move {
  transition: all .3s;
}

.alert-enter{
}
.alert-enter-active{
  animation: alerting .3s;
}
.alert-leave-active{
  animation: alerting .3s reverse;
}
@keyframes alerting {
  0%{
    transform: scale(0);
  }
  50%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}
</style>