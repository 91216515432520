import gsap from "gsap";

export default {
    methods: {
        YTransition() {
            gsap.fromTo('.TransitionPart', {opacity: 0, y: -100}, {
                delay:0.5,
                opacity: 1,
                display: 'block',
                stagger: 0.4,
                y: 0,
                duration: 1
            })
        }
    }
}