<template>
  <div>
    <v-app>
      <v-main>
        <router-view class="fill-height"/>
      </v-main>
    </v-app>
  </div>
</template>

<script>


export default {
  name: 'App',

};
</script>
<style>
.TransitionPart {
  opacity: 0;
}

.page__lines {
  position: absolute;
  background: white;
  inset: 0;
  mask-image: linear-gradient(0deg, transparent, #222, #222);
  -webkit-mask-image: linear-gradient(0deg, transparent, #222, #222);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='30' height='30' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
  background-position: bottom 1px center;
  background-color: hsla(0, 0%, 100%, .25);
}

@media (min-width: 200px) and (max-width: 600px) {
  .fill-height {
    height: 100% !important;
  }
}

.outline_text {
  /*font-size: inherit;*/
  font-weight: bold;
  font-family: xlight !important;
  color: transparent;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}

.fill-height {
  height: 89vh;
}

body {
  overflow: auto;
  position: relative;
}

/*@font-face {*/
/*  font-family: main;*/
/*  src: url("assets/fonts/FontsFree-Net-ClashDisplay-Variable.ttf");*/
/*}*/


/*@font-face {*/
/*  font-family: bold;*/
/*  src: url("assets/fonts/ClashDisplay-Bold.otf");*/
/*}*/

/*@font-face {*/
/*  font-family: light;*/
/*  src: url("assets/fonts/ClashDisplay-Light.otf");*/
/*}*/

@font-face {
  font-family: xlight;
  src: url("assets/fonts/ClashDisplay-Extralight.otf");
}

/*@font-face {*/
/*  font-family: regular;*/
/*  src: url("assets/fonts/ClashDisplay-Regular.otf");*/
/*}*/


@font-face {
  font-family: main;
  font-style: normal;
  font-weight: 400;
  src: local('Clash Display'), url("assets/fonts/ClashDisplayRegular.woff") format('woff')
}

@font-face {
  font-family: xlight;
  font-style: normal;
  font-weight: 200;
  src: local('Clash Display'), url("assets/fonts/ClashDisplayExtralight.woff") format('woff')
}

@font-face {
  font-family: light;
  font-style: normal;
  font-weight: 300;
  src: local('Clash Display'), url("assets/fonts/ClashDisplayLight.woff") format('woff')
}

@font-face {
  font-family: semiBold;
  font-style: normal;
  font-weight: 500;
  src: local('Clash Display'), url("assets/fonts/ClashDisplayMedium.woff") format('woff')
}

@font-face {
  font-family: bold;
  font-style: normal;
  font-weight: 600;
  src: local('Clash Display'), url("assets/fonts/ClashDisplaySemibold.woff") format('woff')
}

@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 700;
  src: local('Clash Display'), url("assets/fonts/ClashDisplayBold.woff") format('woff')
}

* {
  font-family: main !important;
  color: black;
  scroll-behavior: smooth;
}

.font-weight-bold {
  font-family: bold !important;
  color: black;

}

.font-weight-light {
  font-family: light !important;
}

.font-weight-regular {
  font-family: regular !important;
}
</style>