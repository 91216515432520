import Vue from 'vue'
import VueRouter from 'vue-router'
import todosView from "@/views/TodosView";
import boxView from "@/views/BoxView";
import cardsView from "@/views/CardsView";
import timeLineView from "@/views/timeLineView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '',
        component: () => import('../layouts/DefaultLayout'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('../views/HomeView')
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('../views/AboutView.vue')
            },
            {
                path: 'skills',
                name: 'skills',
                component: () => import('../views/SkillsView')
            },
            {
                path: 'experiences',
                name: 'experience',
                component: () => import('../views/ExperienceView')
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import('../views/ContactView')
            },
        ]
    },

    {
        path: '/todos',
        name: 'todos',
        component: todosView,
    },
    {
        path: '/box',
        name: 'box',
        component: boxView,
    },
    {
        path: '/cards',
        name: 'cards',
        component: cardsView,
    },
    {
        path: '/timelines',
        name: 'timeLines',
        component: timeLineView,
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
