<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="box green" v-for="(item,i) in items" :key="i">{{item}}</div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  data(){
    return{
      items:[
          1,2,4,5,6
      ]
    }
  },
  mounted() {
    gsap.from('.box',0.3,{opacity : 0 , y : 200 ,scale:0 , stagger : .3} )
  }

}
</script>

<style scoped>
.box{
  width: 150px;
  height: 150px;
  padding: 20px;
  text-align: center;
}
</style>