<template>
  <div>
    <div class="box first"></div>
    <div class="box second"></div>
    <div class="box third"></div>
    <v-btn class="btn" @click="boxHandler">Start</v-btn>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  methods:  {
      boxHandler(){
        let timeLine = gsap.timeline();
        timeLine.to('.first' , { x : 700 , duration : 2 , ease : "expo.out"})
        timeLine.to('.second' , { x : 700 , duration : 2 , ease : "expo.out"} )
        timeLine.to('.third' , { x : 700 , duration : 2 , ease : "expo.out"})
        timeLine.to('.btn' , { background : 'blue' , duration : 2 , ease : "expo.out"})
        console.log(timeLine.duration())
      }
  }
}
</script>

<style scoped>
.box{
  width: 100px;
  height: 100px;
  background: red;
  margin: 10px;
}
</style>